var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", { staticClass: " " }, [
        _c(
          "div",
          { staticClass: "inner-container only-heading-part mt-0 " },
          [
            _c(
              "v-container",
              {
                staticClass: "v-main-content only-simple-content pt-0",
                attrs: { fluid: "" },
              },
              [
                [
                  _c(
                    "div",
                    { attrs: { id: "app" } },
                    [
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center mb-8" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goToRules()
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v("mdi-arrow-left"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "h5",
                              {
                                staticClass: "primarybase--text h5--text-xbold",
                              },
                              [_vm._v("Keyword Journey")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-expansion-panels",
                          {
                            staticClass: "keyword-journey",
                            model: {
                              value: _vm.panel,
                              callback: function ($$v) {
                                _vm.panel = $$v
                              },
                              expression: "panel",
                            },
                          },
                          [
                            _c(
                              "v-expansion-panel",
                              { staticClass: "pa-2", attrs: { value: "0" } },
                              [
                                _c("v-expansion-panel-header", [
                                  _vm.panel == 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            " primarybase white--text rounded-count d-flex justify-center align-center mr-4",
                                        },
                                        [_vm._v("1")]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            " greylight white--text rounded-count d-flex justify-center align-center mr-4",
                                        },
                                        [_vm._v("1")]
                                      ),
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "primarybase--text h5--text",
                                    },
                                    [_vm._v("General")]
                                  ),
                                ]),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "body--text-2 primarybase--text w-70",
                                      },
                                      [
                                        _vm._v(
                                          " Configure below how you want your Keyword Journey to behave by capturing the outcomes, constraints, and details you have for the ad spend this Keyword Journey will manage. "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-form",
                                      {
                                        ref: "kwjGeneralForm",
                                        model: {
                                          value: _vm.kwjGeneralFormValid,
                                          callback: function ($$v) {
                                            _vm.kwjGeneralFormValid = $$v
                                          },
                                          expression: "kwjGeneralFormValid",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "d-flex align-center mt-4",
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass: "mr-4",
                                              attrs: {
                                                label: "Keyword Journey Name",
                                                placeholder: "Name",
                                                required: "",
                                                rules: [
                                                  function (v) {
                                                    return (
                                                      !!v ||
                                                      "Name cannot be empty"
                                                    )
                                                  },
                                                ],
                                              },
                                              model: {
                                                value: _vm.general.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.general,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "general.name",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "d-flex align-center mt-4",
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { staticClass: " " },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      staticClass: " mr-4",
                                                      attrs: {
                                                        placeholder:
                                                          "Select an App",
                                                        label: "App",
                                                        items: _vm.appsList,
                                                        loading:
                                                          _vm.loadingApps,
                                                        "item-text": "appName",
                                                        "item-value": "adamId",
                                                        required: "",
                                                        "prepend-inner-icon":
                                                          "mdi-cellphone",
                                                        "return-object": "",
                                                      },
                                                      on: {
                                                        change: _vm.changeApp,
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "item",
                                                          fn: function (data) {
                                                            return [
                                                              [
                                                                _c("v-img", {
                                                                  staticClass:
                                                                    "rounded-lg",
                                                                  attrs: {
                                                                    color:
                                                                      "secondary",
                                                                    "lazy-src":
                                                                      data.item
                                                                        .appIcon,
                                                                    "max-width":
                                                                      "30",
                                                                    "max-height":
                                                                      "30",
                                                                    "min-width":
                                                                      "20",
                                                                    "min-height":
                                                                      "20",
                                                                    src:
                                                                      data.item
                                                                        .appIcon,
                                                                  },
                                                                }),
                                                                _c(
                                                                  "v-list-item-content",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2 ",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              data
                                                                                .item
                                                                                .appName
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                data.item
                                                                  .attrExist
                                                                  ? _c(
                                                                      "v-label",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "secondary",
                                                                          disabled: true,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Attributed "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                !data.item
                                                                  .attrExist
                                                                  ? _c(
                                                                      "v-label",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "secondary",
                                                                          disabled: true,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Not Attributed "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                            ]
                                                          },
                                                        },
                                                      ]),
                                                      model: {
                                                        value:
                                                          _vm.general
                                                            .selectedApp,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.general,
                                                            "selectedApp",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "general.selectedApp",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c("v-autocomplete", {
                                                      staticClass: " mr-4",
                                                      attrs: {
                                                        placeholder:
                                                          "Select campaigns",
                                                        label: "Campaigns",
                                                        items: _vm.campsList,
                                                        loading:
                                                          _vm.loadingCamps,
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        required: "",
                                                        multiple: "",
                                                        "validate-on-blur": "",
                                                        rules: [
                                                          function (v) {
                                                            return (
                                                              (!!v &&
                                                                v.length > 0) ||
                                                              "Please select atleast 1 campaign"
                                                            )
                                                          },
                                                        ],
                                                        "prepend-inner-icon":
                                                          "mdi-campaign",
                                                        "return-object": "",
                                                        disabled:
                                                          !_vm.appsList ||
                                                          _vm.appsList
                                                            .length === 0,
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.campaignsSelected,
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "item",
                                                          fn: function (data) {
                                                            return [
                                                              [
                                                                _c(
                                                                  "v-list-item-content",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2 ",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              data
                                                                                .item
                                                                                .name
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-label",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "secondary",
                                                                      disabled: true,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .status
                                                                      ) + " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                            ]
                                                          },
                                                        },
                                                      ]),
                                                      model: {
                                                        value:
                                                          _vm.general
                                                            .selectedCamps,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.general,
                                                            "selectedCamps",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "general.selectedCamps",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "4",
                                                      md: "4",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "pr-0",
                                                            attrs: {
                                                              cols: "12",
                                                              lg: "4",
                                                              md: "4",
                                                              sm: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-radio-group",
                                                              {
                                                                staticClass:
                                                                  "mt-0 pt-0",
                                                                model: {
                                                                  value:
                                                                    _vm.general
                                                                      .asaOrgoal,
                                                                  callback: function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.general,
                                                                      "asaOrgoal",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "general.asaOrgoal",
                                                                },
                                                              },
                                                              [
                                                                _c("v-radio", {
                                                                  attrs: {
                                                                    label:
                                                                      "ASA Install",
                                                                    value:
                                                                      "ASA Install",
                                                                  },
                                                                }),
                                                                _c("v-radio", {
                                                                  attrs: {
                                                                    label:
                                                                      "GOAL Event",
                                                                    value:
                                                                      "GOAL Event",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "pl-0",
                                                            attrs: {
                                                              cols: "12",
                                                              lg: "8",
                                                              md: "8",
                                                              sm: "8",
                                                            },
                                                          },
                                                          [
                                                            _vm.general
                                                              .asaOrgoal ===
                                                            "GOAL Event"
                                                              ? _c(
                                                                  "v-autocomplete",
                                                                  {
                                                                    ref:
                                                                      "goalsRef",
                                                                    staticClass:
                                                                      "mr-4",
                                                                    attrs: {
                                                                      placeholder:
                                                                        "Select Goals",
                                                                      label:
                                                                        "Goals",
                                                                      items:
                                                                        _vm.goalList,
                                                                      loading:
                                                                        _vm.loadingGoals,
                                                                      "item-text":
                                                                        "name",
                                                                      "item-value":
                                                                        "_id",
                                                                      required:
                                                                        "",
                                                                      "prepend-inner-icon":
                                                                        "mdi-bullseye",
                                                                      "return-object":
                                                                        "",
                                                                      multiple:
                                                                        "",
                                                                      "validate-on-blur":
                                                                        "",
                                                                      rules: [
                                                                        function (
                                                                          v
                                                                        ) {
                                                                          return (
                                                                            (!!v &&
                                                                              v.length >
                                                                                0) ||
                                                                            "Please select atleast 1 goal"
                                                                          )
                                                                        },
                                                                      ],
                                                                      disabled:
                                                                        !_vm.appsList ||
                                                                        _vm
                                                                          .appsList
                                                                          .length ===
                                                                          0,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .general
                                                                          .selectedGoals,
                                                                      callback: function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.general,
                                                                          "selectedGoals",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "general.selectedGoals",
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "2",
                                                      md: "2",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: " mr-4",
                                                      attrs: {
                                                        label: "Lookback days",
                                                        placeholder: "lookback",
                                                        rules: _vm.lookbackRule,
                                                        required: "",
                                                        type: "number",
                                                        max: "30",
                                                        min: "1",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.general.lookback,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.general,
                                                            "lookback",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "general.lookback",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "d-flex align-center mt-4",
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "v-search max-256 mr-4 d-flex flex-column justify-start",
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass: "mb-3",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Send Reports"
                                                            ),
                                                          ]
                                                        ),
                                                        _c("v-checkbox", {
                                                          staticClass: "my-0",
                                                          attrs: {
                                                            height: "0",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.general
                                                                .sendReports,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.general,
                                                                "sendReports",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "general.sendReports",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      staticClass:
                                                        "v-sel-custom v-sel-custom-rule",
                                                      attrs: {
                                                        items: _vm.emailList,
                                                        "item-text": "Email",
                                                        "item-value": "Email",
                                                        label: _vm.$t(
                                                          "createAR.selectEmail"
                                                        ),
                                                        multiple: "",
                                                        attach: "",
                                                        chips: "",
                                                        rules:
                                                          _vm.sendReportRule,
                                                        disabled: !_vm.general
                                                          .sendReports,
                                                        loading:
                                                          _vm.$apollo.queries
                                                            .getEmails.loading,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.emailSelectionChanged()
                                                        },
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          _vm.emailList.length >
                                                          0
                                                            ? {
                                                                key:
                                                                  "prepend-item",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-divider",
                                                                      {
                                                                        staticClass:
                                                                          "mt-2",
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "v-list-item",
                                                                      {
                                                                        attrs: {
                                                                          ripple:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.toggleEmailSelectAll()
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-list-item-action",
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs: {
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.selectAllEmailIcon
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-list-item-content",
                                                                          [
                                                                            _c(
                                                                              "v-list-item-title",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "createAR.selectAll"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-divider",
                                                                      {
                                                                        staticClass:
                                                                          "mt-2",
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              }
                                                            : null,
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.selectedMailIds,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.selectedMailIds = $$v
                                                        },
                                                        expression:
                                                          "selectedMailIds",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      staticClass:
                                                        "v-sel-custom",
                                                      attrs: {
                                                        items:
                                                          _vm.slackChannelList,
                                                        label: _vm.$t(
                                                          "createAR.selectChannel"
                                                        ),
                                                        "no-data-text": _vm.$t(
                                                          "createAR.noDataAvailable"
                                                        ),
                                                        "item-value":
                                                          "IntegrationID",
                                                        "item-text": "channel",
                                                        attach: "",
                                                        chips: "",
                                                        multiple: "",
                                                        rules:
                                                          _vm.sendReportRule,
                                                        disabled: !_vm.general
                                                          .sendReports,
                                                        loading:
                                                          _vm.$apollo.queries
                                                            .getSlacks.loading,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.slackSelectionChanged()
                                                        },
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          _vm.slackChannelList
                                                            .length > 0
                                                            ? {
                                                                key:
                                                                  "prepend-item",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-divider",
                                                                      {
                                                                        staticClass:
                                                                          "mt-2",
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "v-list-item",
                                                                      {
                                                                        attrs: {
                                                                          ripple:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.toggleSlackSelectAll()
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-list-item-action",
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs: {
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.selectAllSlackIcon
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-list-item-content",
                                                                          [
                                                                            _c(
                                                                              "v-list-item-title",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "createAR.selectAll"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-divider",
                                                                      {
                                                                        staticClass:
                                                                          "mt-2",
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              }
                                                            : null,
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.selectedSlacks,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.selectedSlacks = $$v
                                                        },
                                                        expression:
                                                          "selectedSlacks",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c("v-combobox", {
                                                      ref: "emailSelectRef",
                                                      staticClass:
                                                        "v-sel-custom",
                                                      attrs: {
                                                        multiple: "",
                                                        label:
                                                          "Enter other Email Ids",
                                                        chips: "",
                                                        "deletable-chips": "",
                                                        rules:
                                                          _vm.csvEmailRules,
                                                        disabled: !_vm.general
                                                          .sendReports,
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.onModelValueChange,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.externalMailIds,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.externalMailIds = $$v
                                                        },
                                                        expression:
                                                          "externalMailIds",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "w-100 d-flex justify-end mt-4",
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "px-6",
                                                attrs: { color: "primary" },
                                                on: {
                                                  click: _vm.goToImpressionStep,
                                                },
                                              },
                                              [_vm._v(" Next > ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-expansion-panel",
                              {
                                staticClass: "pa-2 mt-4",
                                attrs: {
                                  value: "1",
                                  disabled: !_vm.enableImpPanel,
                                },
                              },
                              [
                                _c("v-expansion-panel-header", [
                                  _vm.panel == 1
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            " primarybase white--text rounded-count d-flex justify-center align-center mr-4",
                                        },
                                        [_vm._v("2")]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            " greylight white--text rounded-count d-flex justify-center align-center mr-4",
                                        },
                                        [_vm._v("2")]
                                      ),
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "primarybase--text h5--text",
                                    },
                                    [
                                      _vm._v("Impression "),
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              "close-on-content-click": false,
                                              "nudge-width": 200,
                                              "offset-y": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              color:
                                                                "greylight",
                                                              dark: "",
                                                              small: "",
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " mdi-help-circle-outline "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                            model: {
                                              value: _vm.impressionToolTip,
                                              callback: function ($$v) {
                                                _vm.impressionToolTip = $$v
                                              },
                                              expression: "impressionToolTip",
                                            },
                                          },
                                          [
                                            _c("v-card", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "keyword-journey-info-card",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        " d-flex justify-space-between align-center w-100",
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass:
                                                            "font-800",
                                                        },
                                                        [_vm._v("Impression")]
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.impressionToolTip = false
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("mdi-close")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "keyword-journey-info-contnet",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-14",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "The Impression stage of the Keyword Journey is the initial stage for keywords that have only received impressions within the lookback window. During this stage, bids will incrementally increase by the Bid Increase amount until the keyword receives a tap or the bid reaches the maximum bid constraint. "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("ul", [
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "font-14",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Bid Increase: The amount automation will increase a keyword's current bid if no taps have occured within the lookback window."
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "font-14",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Max Bid: Max allowable bid when a keyword has resulted in impressions but no taps within the lookback window."
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "font-14",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Min Bid: If a bid is lower than the Min Bid, it will be raised to the Min Bid for a keyword that has resulted in impressions but no taps within the lookback window."
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-14",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Note: You can always hover over each field to see info to it specifically."
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                    ],
                                    2
                                  ),
                                ]),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "body--text-2 primarybase--text w-70",
                                      },
                                      [
                                        _vm._v(
                                          " Please capture below the constraints and behaviors you would like applied to keywords that haven't resulted in any taps within the specified lookback window. "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-form",
                                      {
                                        ref: "kwjImpressionForm",
                                        model: {
                                          value: _vm.kwjImpressionFormValid,
                                          callback: function ($$v) {
                                            _vm.kwjImpressionFormValid = $$v
                                          },
                                          expression: "kwjImpressionFormValid",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "d-flex align-center mt-4",
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Max Bid",
                                                                          prefix:
                                                                            "$",
                                                                          type:
                                                                            "number",
                                                                          min:
                                                                            "0",
                                                                          rules:
                                                                            _vm.impressionMaxBidRules,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .impression
                                                                              .maxBid,
                                                                          callback: function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.impression,
                                                                              "maxBid",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "impression.maxBid",
                                                                        },
                                                                      },
                                                                      "v-text-field",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " Max allowable bid when a keyword has resulted in impressions but no taps within the lookback window. "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Min Bid",
                                                                          prefix:
                                                                            "$",
                                                                          type:
                                                                            "number",
                                                                          min:
                                                                            "0",
                                                                          rules:
                                                                            _vm.impressionMinBidRules,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .impression
                                                                              .minBid,
                                                                          callback: function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.impression,
                                                                              "minBid",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "impression.minBid",
                                                                        },
                                                                      },
                                                                      "v-text-field",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " If a bid is lower than the Min Bid, it Will be raised to the Min Bid for a keyword that has resulted in impressions but no taps within the lookback window. "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Bid Increase",
                                                                          prefix:
                                                                            "$",
                                                                          type:
                                                                            "number",
                                                                          min:
                                                                            "0",
                                                                          rules:
                                                                            _vm.impressionBidIncreaseRules,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .impression
                                                                              .bidIncrease,
                                                                          callback: function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.impression,
                                                                              "bidIncrease",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "impression.bidIncrease",
                                                                        },
                                                                      },
                                                                      "v-text-field",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " The amount automation Will increase a keyword's current bid if no taps have occured within the lookback window. "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "w-100 d-flex justify-end mt-4",
                                        attrs: { elevation: "0" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "px-6",
                                            attrs: { color: "primary" },
                                            on: { click: _vm.goToTapStep },
                                          },
                                          [_vm._v(" Next > ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-expansion-panel",
                              {
                                staticClass: "pa-2 mt-4",
                                attrs: {
                                  value: "2",
                                  disabled: !_vm.enableTapPanel,
                                },
                              },
                              [
                                _c("v-expansion-panel-header", [
                                  _vm.panel == 2
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            " primarybase white--text rounded-count d-flex justify-center align-center mr-4",
                                        },
                                        [_vm._v("3")]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            " greylight white--text rounded-count d-flex justify-center align-center mr-4",
                                        },
                                        [_vm._v("3")]
                                      ),
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "primarybase--text h5--text",
                                    },
                                    [
                                      _vm._v("Tap "),
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              "close-on-content-click": false,
                                              "nudge-width": 200,
                                              "offset-y": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              color:
                                                                "greylight",
                                                              dark: "",
                                                              small: "",
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " mdi-help-circle-outline "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                            model: {
                                              value: _vm.tapToolTip,
                                              callback: function ($$v) {
                                                _vm.tapToolTip = $$v
                                              },
                                              expression: "tapToolTip",
                                            },
                                          },
                                          [
                                            _c("v-card", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "keyword-journey-info-card",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        " d-flex justify-space-between align-center w-100",
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass:
                                                            "font-800",
                                                        },
                                                        [_vm._v("Tap")]
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.tapToolTip = false
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("mdi-close")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "keyword-journey-info-contnet",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-14",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "The Tap stage of the Keyword Journey applies to keywords that have received taps and impressions within the lookback window but haven't yet resulted in installs or purchases. In this stage, bids will continue to incrementally increase by the Bid Increase amount until the keyword achieves an install, the bid reaches the maximum bid constraint, or the keyword's spend reaches the spend cap constraint. "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("ul", [
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "font-14",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Spend Cap: the max amount the rule will allow to be spent on a keyword without it ever resulting in an install. Once this max is met, the keyword's bid will be set to the Min Bid "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "font-14",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Max Bid: same concept as in the impression stage, but could be a different value if they want it to be"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "font-14",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Bid Increase: same concept as in the impression stage, but could be a different value if they want it to be"
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-14",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Note: You can always hover over each field to see info to it specifically."
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                    ],
                                    2
                                  ),
                                ]),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "body--text-2 primarybase--text w-70",
                                      },
                                      [
                                        _vm._v(
                                          " Please capture below the constraints and behaviors you would like applied to keywords that have resulted in taps, but no installs, within the specified lookback window. "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-form",
                                      {
                                        ref: "kwjTapForm",
                                        model: {
                                          value: _vm.kwjTapFormValid,
                                          callback: function ($$v) {
                                            _vm.kwjTapFormValid = $$v
                                          },
                                          expression: "kwjTapFormValid",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "d-flex align-center mt-4",
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Spend Cap",
                                                                          prefix:
                                                                            "$",
                                                                          type:
                                                                            "number",
                                                                          min:
                                                                            "0",
                                                                          rules:
                                                                            _vm.numberRules,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .tap
                                                                              .spendCap,
                                                                          callback: function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.tap,
                                                                              "spendCap",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "tap.spendCap",
                                                                        },
                                                                      },
                                                                      "v-text-field",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " Max allowable spend on a keyword that has resulted in taps but no installs within the lookback window. Once the spend Cap is met, the keyword's bid is Set to the Min Bid. "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Max Bid",
                                                                          prefix:
                                                                            "$",
                                                                          type:
                                                                            "number",
                                                                          min:
                                                                            "0",
                                                                          rules:
                                                                            _vm.tapMaxBidRules,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .tap
                                                                              .maxBid,
                                                                          callback: function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.tap,
                                                                              "maxBid",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "tap.maxBid",
                                                                        },
                                                                      },
                                                                      "v-text-field",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " Max allowable bid when a keyword has resulted in taps but n0 installs within the lookback window. "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Min Bid",
                                                                          prefix:
                                                                            "$",
                                                                          type:
                                                                            "number",
                                                                          min:
                                                                            "0",
                                                                          rules:
                                                                            _vm.tapMinBidRules,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .tap
                                                                              .minBid,
                                                                          callback: function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.tap,
                                                                              "minBid",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "tap.minBid",
                                                                        },
                                                                      },
                                                                      "v-text-field",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " Bid value that will be set if the Spend Cap has been met for a keyword that has resulted in taps but no installs. The intent is to slow or stop spend for an underperforming keyword. "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Bid Increase",
                                                                          prefix:
                                                                            "$",
                                                                          type:
                                                                            "number",
                                                                          min:
                                                                            "0",
                                                                          rules:
                                                                            _vm.tapBidIncreaseRules,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .tap
                                                                              .bidIncrease,
                                                                          callback: function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.tap,
                                                                              "bidIncrease",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "tap.bidIncrease",
                                                                        },
                                                                      },
                                                                      "v-text-field",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " The amount automation will increase a keyword's current bid if no installs have occured, but taps have within the lookback window. "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "w-100 d-flex justify-end mt-4",
                                        attrs: { elevation: "0" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "px-6",
                                            attrs: { color: "primary" },
                                            on: { click: _vm.goToInstallStep },
                                          },
                                          [_vm._v(" Next > ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-expansion-panel",
                              {
                                staticClass: "pa-2 mt-4",
                                attrs: {
                                  value: "3",
                                  disabled: !_vm.enableInstallPanel,
                                },
                              },
                              [
                                _c("v-expansion-panel-header", [
                                  _vm.panel == 3
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            " primarybase white--text rounded-count d-flex justify-center align-center mr-4",
                                        },
                                        [_vm._v("4")]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            " greylight white--text rounded-count d-flex justify-center align-center mr-4",
                                        },
                                        [_vm._v("4")]
                                      ),
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "primarybase--text h5--text",
                                    },
                                    [
                                      _vm._v("Install "),
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              "close-on-content-click": false,
                                              "nudge-width": 200,
                                              "offset-y": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              color:
                                                                "greylight",
                                                              dark: "",
                                                              small: "",
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " mdi-help-circle-outline "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                            model: {
                                              value: _vm.insatllToolTip,
                                              callback: function ($$v) {
                                                _vm.insatllToolTip = $$v
                                              },
                                              expression: "insatllToolTip",
                                            },
                                          },
                                          [
                                            _c("v-card", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "keyword-journey-info-card",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        " d-flex justify-space-between align-center w-100",
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass:
                                                            "font-800",
                                                        },
                                                        [_vm._v("Install")]
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.insatllToolTip = false
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("mdi-close")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "keyword-journey-info-contnet",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-14",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "The Install stage of the Keyword Journey applies to keywords that have achieved installs, taps, and impressions within the lookback window but haven't yet resulted in a goal event. In this stage, bids are set to the target Cost Per Install (CPI) multiplied by the ratio of installs to taps. Additionally, the bids are subject to the maximum bid constraint and the spend cap constraint. "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("ul", [
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "font-14",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Target CPI: Defines the highest acceptable cost per install for a keyword that has resulted in installs but no goal events within the lookback window. "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "font-14",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Max Bid: Max allowable bid when a keyword has resulted in installs but no goal events within the lookback window. "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "font-14",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Min Bid: Bid value that will be set if the Spend Cap has been met for a keyword that has resulted in installs but no goal events. The intent is to slow or stop spend for an underperforming keyword. "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "font-14",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Spend Cap: Max allowable spend on a keyword that has resulted in installs but no goal events within the lookback window. Once the spend cap is met, the keyword's bid is set to the Min Bid. "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-14",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Note: You can always hover over each field to see info to it specifically."
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                    ],
                                    2
                                  ),
                                ]),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "body--text-2 primarybase--text w-70",
                                      },
                                      [
                                        _vm._v(
                                          " Please capture below the constraints and behaviors you would like applied to keywords that have resulted in installs, but no goal events, within the specified lookback window. "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-form",
                                      {
                                        ref: "kwjInstallForm",
                                        model: {
                                          value: _vm.kwjInstallFormValid,
                                          callback: function ($$v) {
                                            _vm.kwjInstallFormValid = $$v
                                          },
                                          expression: "kwjInstallFormValid",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "d-flex align-center mt-4",
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Target CPI",
                                                                          prefix:
                                                                            "$",
                                                                          type:
                                                                            "number",
                                                                          min:
                                                                            "0",
                                                                          rules:
                                                                            _vm.numberRules,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .install
                                                                              .targetCPI,
                                                                          callback: function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.install,
                                                                              "targetCPI",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "install.targetCPI",
                                                                        },
                                                                      },
                                                                      "v-text-field",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " Defines the highest acceptable cost per install for a keyword that has resulted in installs but no goal events within the lookback window. "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Max Bid",
                                                                          prefix:
                                                                            "$",
                                                                          type:
                                                                            "number",
                                                                          min:
                                                                            "0",
                                                                          rules:
                                                                            _vm.installMaxBidRules,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .install
                                                                              .maxBid,
                                                                          callback: function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.install,
                                                                              "maxBid",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "install.maxBid",
                                                                        },
                                                                      },
                                                                      "v-text-field",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " Max allowable bid when a keyword has resulted in installs but no goal events within the lookback window "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "3",
                                                      md: "3",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Min Bid",
                                                                          prefix:
                                                                            "$",
                                                                          type:
                                                                            "number",
                                                                          min:
                                                                            "0",
                                                                          rules:
                                                                            _vm.installMinBidRules,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .install
                                                                              .minBid,
                                                                          callback: function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.install,
                                                                              "minBid",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "install.minBid",
                                                                        },
                                                                      },
                                                                      "v-text-field",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " Bid value that will be set if the Spend Cap has been met for a keyword that has resulted in installs but no goal events. The intent is to slow or stop spend for an underperforming keyword. "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm.general.asaOrgoal ===
                                                "GOAL Event"
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          lg: "3",
                                                          md: "3",
                                                          sm: "12",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              attrs: {
                                                                                label:
                                                                                  "Spend Cap",
                                                                                prefix:
                                                                                  "$",
                                                                                type:
                                                                                  "number",
                                                                                min:
                                                                                  "0",
                                                                                rules:
                                                                                  _vm.numberRules,
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .install
                                                                                    .spendCap,
                                                                                callback: function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.install,
                                                                                    "spendCap",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "install.spendCap",
                                                                              },
                                                                            },
                                                                            "v-text-field",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        )
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              501159420
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " Max allowable spend on a keyword that has resulted in installs but no goal events within the lookback window. Once the spend cap is met, the keyword's bid is set to the Min Bid. "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.general.asaOrgoal === "GOAL Event"
                                      ? _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "w-100 d-flex justify-end mt-4",
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "px-6",
                                                attrs: { color: "primary" },
                                                on: {
                                                  click: _vm.goToEventStep,
                                                },
                                              },
                                              [_vm._v(" Next > ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.general.asaOrgoal === "ASA Install"
                                      ? _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "w-100 d-flex justify-end mt-4",
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "px-6",
                                                attrs: { color: "primary" },
                                                on: { click: _vm.goToSaveStep },
                                              },
                                              [_vm._v(" Next > ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.general.asaOrgoal === "GOAL Event"
                              ? _c(
                                  "v-expansion-panel",
                                  {
                                    staticClass: "pa-2 mt-4",
                                    attrs: {
                                      value: "4",
                                      disabled: !_vm.enableEventPanel,
                                    },
                                  },
                                  [
                                    _c("v-expansion-panel-header", [
                                      _vm.panel == 4
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                " primarybase white--text rounded-count d-flex justify-center align-center mr-4",
                                            },
                                            [_vm._v("5")]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass:
                                                " greylight white--text rounded-count d-flex justify-center align-center mr-4",
                                            },
                                            [_vm._v("5")]
                                          ),
                                      _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "primarybase--text h5--text",
                                        },
                                        [
                                          _vm._v("Event "),
                                          [
                                            _c(
                                              "v-menu",
                                              {
                                                attrs: {
                                                  "close-on-content-click": false,
                                                  "nudge-width": 200,
                                                  "offset-y": "",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function (ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "greylight",
                                                                    dark: "",
                                                                    small: "",
                                                                  },
                                                                },
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " mdi-help-circle-outline "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3751645694
                                                ),
                                                model: {
                                                  value: _vm.eventToolTip,
                                                  callback: function ($$v) {
                                                    _vm.eventToolTip = $$v
                                                  },
                                                  expression: "eventToolTip",
                                                },
                                              },
                                              [
                                                _c("v-card", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "keyword-journey-info-card",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            " d-flex justify-space-between align-center w-100",
                                                        },
                                                        [
                                                          _c(
                                                            "h4",
                                                            {
                                                              staticClass:
                                                                "font-800",
                                                            },
                                                            [_vm._v("Event")]
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  _vm.eventToolTip = false
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-close"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "keyword-journey-info-contnet",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-14",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "The Event stage of the Keyword Journey focuses on keywords with achieved goal events, showing promise for true value. The base bid is determined by the CPG target multiplied by the goal events-to-taps ratio for CPG targets, or the revenue per goal-to-target ROAS ratio multiplied by the goal events-to-taps ratio for ROAS targets. A bid boost, up to the Max Boost Multiplier, is applied to ensure adequate traffic, gradually reducing as goal events approach the Min Event Count. The assigned bid will always adhere to the maximum bid constraint. "
                                                              ),
                                                            ]
                                                          ),
                                                          _c("ul", [
                                                            _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "font-14",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Max Bid: Max allowable bid when a keyword has resulted in goal events within the lookback window totalling less than the Min Event Count. "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "font-14",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Return On Ad Spend (ROAS): Defines the lowest acceptable return on ad spend (ROAS) for a keyword that has resulted in goal events within the lookback window totalling less than the Min Event Count. "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "font-14",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Cost Per Goal (CPC): Defines the highest acceptable cost per goal event for a keyword that has resulted in goal events within the lookback window totalling less than the Min Event Count. "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "font-14",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Max Boost Multiplier: Max percent increase allowable for a bid boost. Bid boosts are applied to a keyword that has resulted in goal events within the lookback window totalling less than the Min Event Count, and are intended to generate more results for promising keywords. "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "font-14",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "• Min Event Count: Amount of events required before a keywords bid amount can be based entirely on ROAS or CPG targets. Under this limit, automation works to balance leaning in on promising keywords with temporing calculated bid amounts based on limited data. "
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-14",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Note: You can always hover over each field to see info to it specifically."
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                        ],
                                        2
                                      ),
                                    ]),
                                    _c(
                                      "v-expansion-panel-content",
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "body--text-2 primarybase--text w-70",
                                          },
                                          [
                                            _vm._v(
                                              " Please capture below the constraints and behaviors you would like applied to keywords that haven't resulted in any taps within the specified lookback window. "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-form",
                                          {
                                            ref: "kwjEventForm",
                                            model: {
                                              value: _vm.kwjEventFormValid,
                                              callback: function ($$v) {
                                                _vm.kwjEventFormValid = $$v
                                              },
                                              expression: "kwjEventFormValid",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass:
                                                  "d-flex align-end mt-4",
                                                attrs: { elevation: "0" },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "d-flex align-end ",
                                                        attrs: {
                                                          cols: "12",
                                                          lg: "3",
                                                          md: "3",
                                                          sm: "12",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              attrs: {
                                                                                label:
                                                                                  "Max Bid",
                                                                                prefix:
                                                                                  "$",
                                                                                type:
                                                                                  "number",
                                                                                min:
                                                                                  "0",
                                                                                rules:
                                                                                  _vm.numberRules,
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .event
                                                                                    .maxBid,
                                                                                callback: function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.event,
                                                                                    "maxBid",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "event.maxBid",
                                                                              },
                                                                            },
                                                                            "v-text-field",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        )
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              3105936433
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " Max allowable bid when a keyword has resulted in goal events within the lookback window totalling less than the Min Event Count. "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          lg: "3",
                                                          md: "3",
                                                          sm: "12",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-end ",
                                                          },
                                                          [
                                                            _c(
                                                              "v-radio-group",
                                                              {
                                                                staticClass:
                                                                  "mr-4",
                                                                model: {
                                                                  value:
                                                                    _vm.event
                                                                      .roasOrCPG,
                                                                  callback: function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.event,
                                                                      "roasOrCPG",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "event.roasOrCPG",
                                                                },
                                                              },
                                                              [
                                                                _c("v-radio", {
                                                                  attrs: {
                                                                    label:
                                                                      "ROAS",
                                                                    value:
                                                                      "ROAS",
                                                                  },
                                                                }),
                                                                _c("v-radio", {
                                                                  attrs: {
                                                                    label:
                                                                      "CPG",
                                                                    value:
                                                                      "CPG",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _vm.event
                                                              .roasOrCPG ===
                                                            "ROAS"
                                                              ? _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom:
                                                                        "",
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function (
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-text-field",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      attrs: {
                                                                                        label:
                                                                                          "Return On Ad Spend Ratio",
                                                                                        type:
                                                                                          "number",
                                                                                        min:
                                                                                          "0",
                                                                                        rules:
                                                                                          _vm.numberRules,
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .event
                                                                                            .roasRatio,
                                                                                        callback: function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.event,
                                                                                            "roasRatio",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "event.roasRatio",
                                                                                      },
                                                                                    },
                                                                                    "v-text-field",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                )
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      573673534
                                                                    ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        " Defines the lowest acceptable return on ad spend (ROAS) for a keyword that has resulted in goal events within the lookback window totalling less than the Min Event Count. "
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm.event
                                                              .roasOrCPG ===
                                                            "CPG"
                                                              ? _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom:
                                                                        "",
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function (
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-text-field",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      attrs: {
                                                                                        label:
                                                                                          "Target CPG",
                                                                                        type:
                                                                                          "number",
                                                                                        min:
                                                                                          "0",
                                                                                        rules:
                                                                                          _vm.numberRules,
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .event
                                                                                            .targetCPG,
                                                                                        callback: function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.event,
                                                                                            "targetCPG",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "event.targetCPG",
                                                                                      },
                                                                                    },
                                                                                    "v-text-field",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                )
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      677373907
                                                                    ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        " Defines the target cost per goal. "
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "d-flex align-end ",
                                                        attrs: {
                                                          cols: "12",
                                                          lg: "3",
                                                          md: "3",
                                                          sm: "12",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "w-100",
                                                                            },
                                                                            "div",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                placeholder:
                                                                                  "Select",
                                                                                label:
                                                                                  "Max Boost Multiplier",
                                                                                items:
                                                                                  _vm.maxBoostMultiplierList,
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .event
                                                                                    .maxBoostMultiplier,
                                                                                callback: function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.event,
                                                                                    "maxBoostMultiplier",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "event.maxBoostMultiplier",
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              2138647046
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " Max percent increase allowable for a bid boost. Bid boosts are applied to a keyword that has resulted in goal events within the lookback window totalling less than the Min Event Count, and are intended to generate more results for promising keywords. "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "d-flex align-end ",
                                                        attrs: {
                                                          cols: "12",
                                                          lg: "3",
                                                          md: "3",
                                                          sm: "12",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              attrs: {
                                                                                placeholder:
                                                                                  "",
                                                                                label:
                                                                                  "Min Event Count",
                                                                                type:
                                                                                  "number",
                                                                                min:
                                                                                  "0",
                                                                                rules:
                                                                                  _vm.numberRules,
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .event
                                                                                    .minEventCount,
                                                                                callback: function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.event,
                                                                                    "minEventCount",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "event.minEventCount",
                                                                              },
                                                                            },
                                                                            "v-text-field",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        )
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              2807332550
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " Amount Of events required before a keywords bid amount can be based entirely on ROAS or CPG targets. Under this limit, automation works to balance leaning in on promising keywords with temporing calculated bid amounts based on limited data. "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "w-100 d-flex justify-end mt-4",
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "px-6",
                                                attrs: { color: "primary" },
                                                on: { click: _vm.goToSaveStep },
                                              },
                                              [_vm._v(" Next > ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                _c(
                  "div",
                  { staticClass: "w-100 d-flex justify-end mt-4" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "px-6",
                        attrs: { color: "primary", disabled: !_vm.enableSave },
                        on: { click: _vm.createRules },
                      },
                      [_vm._v("Save >")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }