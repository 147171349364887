import _ from 'lodash';
/* 
  - This service is for v-combobox vuetify component
  - When the above is used with multiple and :items is not provided; duplicate addition is automatically removed by vuetify
  - we want to do some action when that is removed; in below case we are notifying an error.
*/

type NotifyOption = {
  component: any;
  externalEmailPropertyName: string;
  mainEmailPropertyName: string;
  message?: string;
};

class DuplicateEmailComboboxService {
  oldValue = null;
  debouncedCheckForDuplicateEmailEntryAndNotifyError = null;

  component = null;
  externalEmailPropertyName = '';
  mainEmailPropertyName = '';
  message = '';

  constructor({
    component,
    externalEmailPropertyName,
    mainEmailPropertyName,
    message = 'Duplicate Email Id has been removed',
  }: NotifyOption) {
    this.component = component;
    this.externalEmailPropertyName = externalEmailPropertyName;
    this.mainEmailPropertyName = mainEmailPropertyName;
    this.message = message;

    this.oldValue = [];

    this.debouncedCheckForDuplicateEmailEntryAndNotifyError = _.debounce(
      this.checkForDuplicateEmailEntryAndNotifyError,
      300
    );
  }

  checkForDuplicateRemovalAndNotifyError() {
    this.filterInOtherEmailsBasedOnSelectedEmails();
    this.debouncedCheckForDuplicateEmailEntryAndNotifyError();
  }

  checkAndInitiateADuplicateRemovalInOtherEmail() {
    /* this flow doesnt initiate the debouned logic */
    let duplicatEmailId = '';
    for (const email of this.externalEmails) {
      if (this.mainEmails.includes(email)) {
        duplicatEmailId = email;
      }
    }
    if (duplicatEmailId) {
      this.notifyMessage();
      this.filterInOtherEmailsBasedOnSelectedEmails();
    }
    this.updateTheOldValueWithCurrentExternalEmails();
  }

  private get externalEmails() {
    return this.component[this.externalEmailPropertyName];
  }

  private set externalEmails(v) {
    this.component[this.externalEmailPropertyName] = v;
  }

  private get mainEmails() {
    return this.component[this.mainEmailPropertyName];
  }

  private set mainEmails(v) {
    this.component[this.mainEmailPropertyName] = v;
  }

  private notifyMessage() {
    this.component.$notify.info(this.message);
  }

  private updateTheOldValueWithCurrentExternalEmails(){
    this.oldValue = [...this.externalEmails];
  }

  private checkForDuplicateEmailEntryAndNotifyError() {
    /* For duplicate data entry, comboRef.internalValue is updated twice frequently so the @change function gets called twice with first one removing the duplicate entry and next time adding a single duplucate entry;
      Thats why we have to use debounce here to tackle the frequent double calls */
    const updatedValue = this.externalEmails;

    const sortedString1 = JSON.stringify([...this.oldValue].sort());
    const sortedString2 = JSON.stringify([...updatedValue].sort());
    if (sortedString1 === sortedString2) {
      this.notifyMessage();
    }
    this.updateTheOldValueWithCurrentExternalEmails();
  }

  private filterInOtherEmailsBasedOnSelectedEmails() {
    this.externalEmails = this.externalEmails.filter((item) => !this.mainEmails.includes(item));
  }
}

export default DuplicateEmailComboboxService;
