<template flex>
  <div class="dashboard"> 
    <v-app class=" ">
      <div class="inner-container only-heading-part mt-0 ">
        <v-container fluid class="v-main-content only-simple-content pt-0">
          <template fluid>

              <div id="app">    
               <template>
                <div class="d-flex align-center mb-8">
                  <v-btn icon @click="goToRules()">
                    <v-icon color="primary" class="">mdi-arrow-left</v-icon>
                  </v-btn>
                  <h5 class="primarybase--text h5--text-xbold">Keyword Journey</h5>
                </div>
                <v-expansion-panels class="keyword-journey" v-model="panel">
                  <!-- general  -->
                  <v-expansion-panel class="pa-2" value="0">
                    <v-expansion-panel-header>
                      <span v-if="panel==0" class=" primarybase white--text rounded-count d-flex justify-center align-center mr-4">1</span> 
                      <span v-else class=" greylight white--text rounded-count d-flex justify-center align-center mr-4">1</span> 
                      <h5 class="primarybase--text h5--text">General</h5>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p class="body--text-2 primarybase--text w-70">
                         Configure below how you want your Keyword Journey to behave by capturing the 
                         outcomes, constraints, and details you have for the ad spend this Keyword Journey will manage.
                      </p>
                    <v-form v-model="kwjGeneralFormValid" ref="kwjGeneralForm" >
                      <v-card elevation="0" class="d-flex align-center mt-4">
                        <v-text-field
                            label="Keyword Journey Name"
                            placeholder="Name"
                            class="mr-4"
                            v-model="general.name"
                            required
                            :rules="[
                              (v) => (!!v) || 'Name cannot be empty',
                            ]"
                          ></v-text-field>
                        </v-card>
                        <v-card elevation="0" class="d-flex align-center mt-4">

                          <v-row class=" ">
                            <v-col cols="12" lg="3" md="3" sm="12" >
                              <v-select
                                placeholder="Select an App"
                                label="App"
                                class=" mr-4"
                                v-model="general.selectedApp"
                                :items="appsList"
                                :loading="loadingApps"
                                item-text="appName"
                                item-value="adamId"
                                required
                                prepend-inner-icon="mdi-cellphone"
                                return-object
                                @change="changeApp"
                              >
                                <template v-slot:item="data">
                                  <template>
                                    <v-img
                                      class="rounded-lg"
                                      color="secondary"
                                      :lazy-src="data.item.appIcon"
                                      max-width="30"
                                      max-height="30"
                                      min-width="20"
                                      min-height="20"
                                      :src="data.item.appIcon"
                                    ></v-img>
                                    <v-list-item-content class="ml-2 ">
                                      <v-list-item-title>
                                        {{
                                        data.item.appName
                                        }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                    <v-label
                                      v-if="data.item.attrExist"
                                      color="secondary"
                                      :disabled="true"
                                      >Attributed
                                    </v-label>
                                    <v-label
                                      v-if="!data.item.attrExist"
                                      color="secondary"
                                      :disabled="true"
                                      >Not Attributed
                                    </v-label>
                                  </template>
                                </template>
                              </v-select>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="12" >
                              <v-autocomplete
                                placeholder="Select campaigns"
                                label="Campaigns"
                                class=" mr-4"
                                v-model="general.selectedCamps"
                                :items="campsList"
                                :loading="loadingCamps"
                                item-text="name"
                                item-value="id"
                                required
                                multiple
                                validate-on-blur
                                :rules="[
                                  (v) => (!!v && v.length > 0) || 'Please select atleast 1 campaign',
                                ]"
                                prepend-inner-icon="mdi-campaign"
                                return-object
                                @change="campaignsSelected"
                                :disabled="!appsList || appsList.length === 0"
                              >
                                <template v-slot:item="data">
                                  <template>                                
                                    <v-list-item-content class="ml-2 ">
                                      <v-list-item-title>
                                        {{
                                          data.item.name
                                        }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                    <v-label
                                      color="secondary"
                                      :disabled="true"
                                      >{{data.item.status}}
                                    </v-label>
                                  </template>
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" lg="4" md="4" sm="12">
                              <v-row>
                                <v-col cols="12" lg="4" md="4" sm="4" class="pr-0">
                                  <v-radio-group v-model="general.asaOrgoal" class="mt-0 pt-0" >
                                    <v-radio
                                      label="ASA Install"
                                      value="ASA Install"
                                    ></v-radio>
                                    <v-radio
                                      label="GOAL Event"
                                      value="GOAL Event"
                                    ></v-radio>
                                </v-radio-group>
                                </v-col>
                                <v-col cols="12" lg="8" md="8" sm="8" class="pl-0">
                                  <v-autocomplete v-if="general.asaOrgoal === 'GOAL Event'"
                                  ref="goalsRef"
                                  placeholder="Select Goals"
                                  label="Goals"
                                  class="mr-4"
                                  v-model="general.selectedGoals"
                                  :items="goalList"
                                  :loading="loadingGoals"
                                  item-text="name"
                                  item-value="_id"
                                  required
                                  prepend-inner-icon="mdi-bullseye"
                                  return-object
                                  multiple
                                  validate-on-blur
                                  :rules="[
                                    (v) => (!!v && v.length > 0) || 'Please select atleast 1 goal',
                                  ]"
                                  :disabled="!appsList || appsList.length === 0"
                                >
                                </v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" lg="2" md="2" sm="12" >
                              <v-text-field
                                label="Lookback days"
                                placeholder="lookback"
                                class=" mr-4"
                                v-model="general.lookback"
                                :rules="lookbackRule"
                                required
                                type="number"
                                max="30"
                                min="1"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                        </v-card>

                      <v-card elevation="0" class="d-flex align-center mt-4">
                        <v-row>
                          <v-col cols="12" lg="3" md="3" sm="12" >
                            <div class="v-search max-256 mr-4 d-flex flex-column justify-start">
                            <label class="mb-3">Send Reports</label>
                            <v-checkbox
                                height="0"
                                class="my-0"
                                v-model="general.sendReports"
                                ></v-checkbox>
                                <!-- label="Send Reports" -->
                            </div>

                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="12" >
                            <v-select
                              class="v-sel-custom v-sel-custom-rule"
                              v-model="selectedMailIds"
                              :items="emailList"
                              item-text="Email"
                              item-value="Email" 
                              :label="$t('createAR.selectEmail')"
                              multiple
                              attach
                              chips
                              :rules="sendReportRule"
                              :disabled="!general.sendReports"
                              :loading="$apollo.queries.getEmails.loading"
                              @change="emailSelectionChanged()"
                            >
                              <template
                                v-slot:prepend-item
                                v-if="emailList.length > 0"
                              >
                                <v-divider class="mt-2"></v-divider>
                                <v-list-item ripple @click="toggleEmailSelectAll()">
                                  <v-list-item-action>
                                    <v-icon
                                      color="primary"
                                    >
                                      {{ selectAllEmailIcon }}
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ $t('createAR.selectAll') }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                            </v-select>

                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="12" >
                            <v-select
                              class="v-sel-custom"
                              v-model="selectedSlacks"
                              :items="slackChannelList"
                              :label="$t('createAR.selectChannel')"
                              :no-data-text="$t('createAR.noDataAvailable')"
                              item-value="IntegrationID"
                              item-text="channel"
                              attach
                              chips
                              multiple
                              :rules="sendReportRule"
                              :disabled="!general.sendReports"
                              :loading="$apollo.queries.getSlacks.loading"
                              @change="slackSelectionChanged()"
                            >
                              <template
                                v-slot:prepend-item
                                v-if="slackChannelList.length > 0"
                              >
                                <v-divider class="mt-2"></v-divider>
                                <v-list-item ripple @click="toggleSlackSelectAll()">
                                  <v-list-item-action>
                                    <v-icon
                                      color="primary"
                                    >
                                      {{ selectAllSlackIcon }}
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ $t('createAR.selectAll') }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                            </v-select>

                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="12" >
                            <v-combobox
                              multiple
                              class="v-sel-custom"
                              v-model="externalMailIds"
                              label="Enter other Email Ids"
                              chips
                              deletable-chips
                              :rules="csvEmailRules"
                              :disabled="!general.sendReports"
                              ref="emailSelectRef"
                              @change="onModelValueChange"
                            ></v-combobox>

                          </v-col>
                        </v-row>

                      </v-card>

                      <v-card elevation="0" class="w-100 d-flex justify-end mt-4">
                        <v-btn color="primary" class="px-6" @click="goToImpressionStep">
                                    Next >
                        </v-btn>
                      </v-card>
                    </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- impression  -->
                   <v-expansion-panel class="pa-2 mt-4" value="1" :disabled="!enableImpPanel">
                    <v-expansion-panel-header>
                      <span v-if="panel==1" class=" primarybase white--text rounded-count d-flex justify-center align-center mr-4">2</span> 
                      <span v-else class=" greylight white--text rounded-count d-flex justify-center align-center mr-4">2</span> 
                      <h5 class="primarybase--text h5--text">Impression
                          <template>
                            <v-menu
                              v-model="impressionToolTip"
                              :close-on-content-click="false"
                              :nudge-width="200"
                              offset-y
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="greylight"
                                  dark
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-help-circle-outline
                                </v-icon>
                              </template>

                              <v-card>
                                <div class="keyword-journey-info-card">
                                  <div class=" d-flex justify-space-between align-center w-100">
                                  <h4 class="font-800">Impression</h4>
                                  <v-icon class="" @click="impressionToolTip=false"
                                    >mdi-close</v-icon
                                  >
                                </div>
                                <div class="keyword-journey-info-contnet">

                                  <span class="font-14">The Impression stage of the Keyword Journey is the initial stage for keywords that have only received impressions 
                                    within the lookback window. During this stage, bids will incrementally increase by the Bid Increase amount until the keyword receives a 
                                    tap or the bid reaches the maximum bid constraint.
                                    
                                  </span>
                                  <ul>
                                    <li class="font-14">Bid Increase: The amount automation will increase a keyword's current bid if no taps have occured within the lookback window.</li>
                                    <li class="font-14">Max Bid: Max allowable bid when a keyword has resulted in impressions but no taps within the lookback window.</li>
                                    <li class="font-14">Min Bid: If a bid is lower than the Min Bid, it will be raised to the Min Bid for a keyword that has resulted in impressions but no taps within the lookback window.</li>
                                  </ul>
                                  <span class="font-14">Note: You can always hover over each field to see info to it specifically.</span>             
                                </div>
                                </div>
                              </v-card>
                            </v-menu>
                          </template>
                      </h5>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p class="body--text-2 primarybase--text w-70">
                         Please capture below the constraints and behaviors you would like applied to keywords
                        that haven't resulted in any taps within the specified lookback window.
                      </p>
                      <v-form v-model="kwjImpressionFormValid" ref="kwjImpressionForm" >
                        <v-card elevation="0" class="d-flex align-center mt-4">
                          <v-row>
                            <v-col cols="12" lg="3" md="3" sm="12" >
                              <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field  
                                  v-bind="attrs"
                                  v-on="on"
                                  label="Max Bid"
                                  prefix="$"
                                  class=""
                                  v-model="impression.maxBid"
                                  type="number" 
                                  min="0"
                                  :rules="impressionMaxBidRules"
                                ></v-text-field>
                            </template>
                            <span>
                              Max allowable bid when a keyword has resulted in impressions
                              but no taps within the lookback window.
                            </span>
                          </v-tooltip>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="12" >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Min Bid"
                                    prefix="$"
                                    class=""
                                    v-model="impression.minBid"
                                    type="number" 
                                    min="0"
                                    :rules="impressionMinBidRules"
                                  ></v-text-field>
                                  
                                </template>
                              <span>
                                If a bid is lower than the Min Bid, it Will be raised to the Min Bid
                                for a keyword that has resulted in impressions but no taps
                                within the lookback window.
                              </span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="12" >
                              <!-- prepend-icon="mdi-currency-usd" -->
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Bid Increase"
                                    prefix="$"
                                    class=""
                                    v-model="impression.bidIncrease"
                                    type="number" 
                                    min="0"
                                    :rules="impressionBidIncreaseRules"
                                  ></v-text-field>
                                  
                                  
                                </template>
                              <span>
                                The amount automation Will increase a keyword's current bid if
                                no taps have occured within the lookback window.
                              </span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-form>
                    <v-card elevation="0" class="w-100 d-flex justify-end mt-4">
                      <v-btn color="primary" class="px-6"  @click="goToTapStep">
                                  Next >
                      </v-btn>
                    </v-card>
                    
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                   <!-- Taps  -->
                   <v-expansion-panel class="pa-2 mt-4" value="2" :disabled="!enableTapPanel">
                    <v-expansion-panel-header>
                      <span v-if="panel==2" class=" primarybase white--text rounded-count d-flex justify-center align-center mr-4">3</span> 
                      <span v-else class=" greylight white--text rounded-count d-flex justify-center align-center mr-4">3</span> 
                      <h5 class="primarybase--text h5--text">Tap
                        <template>
                          <v-menu
                            v-model="tapToolTip"
                            :close-on-content-click="false"
                            :nudge-width="200"
                            offset-y
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="greylight"
                                dark
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                            </template>
                            <v-card>
                                  <div class="keyword-journey-info-card">
                                    <div class=" d-flex justify-space-between align-center w-100">
                                    <h4 class="font-800">Tap</h4>
                                    <v-icon class="" @click="tapToolTip=false"
                                      >mdi-close</v-icon
                                    >
                                  </div>
                                  <div class="keyword-journey-info-contnet">

                                    <span class="font-14">The Tap stage of the Keyword Journey applies to keywords that have received
                                      taps and impressions within the lookback window but haven't yet resulted in installs or purchases.
                                      In this stage, bids will continue to incrementally increase by the Bid Increase amount until the 
                                      keyword achieves an install, the bid reaches the maximum bid constraint, or the keyword's spend 
                                      reaches the spend cap constraint. 
                                    </span>
                                    <ul>
                                      <li class="font-14">Spend Cap: the max amount the rule will allow to be spent on a keyword 
                                        without it ever resulting in an install.  Once this max is met, the keyword's bid will be set to the Min Bid
                                      </li>
                                      <li class="font-14">Max Bid: same concept as in the impression stage, but could be a 
                                        different value if they want it to be</li>
                                      <li class="font-14">Bid Increase: same concept as in the impression stage, but could be a different value if they want it to be</li>
                                    </ul>
                                    <span class="font-14">Note: You can always hover over each field to see info to it specifically.</span>             
                                  </div>
                                  </div>
                                </v-card>
                          </v-menu>
                        </template>
                      </h5>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p class="body--text-2 primarybase--text w-70">
                       Please capture below the constraints and behaviors you would like applied to keywords
                        that have resulted in taps, but no installs, within the specified lookback window.
                      </p>
                      <v-form v-model="kwjTapFormValid" ref="kwjTapForm" >
                        <v-card elevation="0" class="d-flex align-center mt-4">
                          <v-row>
                            <v-col cols="12" lg="3" md="3" sm="12" >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Spend Cap"
                                    class=""
                                    prefix="$"
                                    v-model="tap.spendCap"
                                    type="number" 
                                    min="0"
                                    :rules="numberRules"
                                  ></v-text-field> 
                                </template>
                              <span>
                                Max allowable spend on a keyword that has resulted in taps but
                                no installs within the lookback window. Once the spend Cap is
                                met, the keyword's bid is Set to the Min Bid.
                              </span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="12" >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Max Bid"
                                    class=""
                                    prefix="$"
                                    v-model="tap.maxBid"
                                    type="number" 
                                    min="0"
                                    :rules="tapMaxBidRules"
                                  ></v-text-field>
                                  
                                </template>
                              <span>
                                Max allowable bid when a keyword has resulted in taps but n0
                                installs within the lookback window.
                              </span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="12" >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Min Bid"
                                    class=""
                                    prefix="$"
                                    v-model="tap.minBid"
                                    type="number" 
                                    min="0"
                                    :rules="tapMinBidRules"
                                  ></v-text-field>
                                </template>
                              <span>
                                Bid value that will be set if the Spend Cap has been met for a
                                keyword that has resulted in taps but no installs. The intent is
                                to slow or stop spend for an underperforming keyword.
                              </span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="12" >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                  v-bind="attrs"
                                  v-on="on"
                                  label="Bid Increase"
                                  class=""
                                  prefix="$"
                                  v-model="tap.bidIncrease"
                                  type="number" 
                                  min="0"
                                  :rules="tapBidIncreaseRules"
                              ></v-text-field>  
                                </template>
                              <span>
                                The amount automation will increase a keyword's current bid if
                                no installs have occured, but taps have within the lookback
                                window.
                              </span>
                              </v-tooltip> 
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-form>
                    <v-card elevation="0" class="w-100 d-flex justify-end mt-4">
                      <v-btn color="primary" class="px-6" @click="goToInstallStep">
                                  Next >
                      </v-btn>
                    </v-card>
                    
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                   <!-- Installs  -->
                   <v-expansion-panel class="pa-2 mt-4" value="3" :disabled="!enableInstallPanel">
                    <v-expansion-panel-header>
                      <span v-if="panel==3" class=" primarybase white--text rounded-count d-flex justify-center align-center mr-4">4</span> 
                      <span v-else class=" greylight white--text rounded-count d-flex justify-center align-center mr-4">4</span> 
                      <h5 class="primarybase--text h5--text">Install
                        <template>
                          <v-menu
                            v-model="insatllToolTip"
                            :close-on-content-click="false"
                            :nudge-width="200"
                            offset-y
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="greylight"
                                dark
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                            </template>
                            <v-card>
                                  <div class="keyword-journey-info-card">
                                    <div class=" d-flex justify-space-between align-center w-100">
                                    <h4 class="font-800">Install</h4>
                                    <v-icon class="" @click="insatllToolTip=false"
                                      >mdi-close</v-icon
                                    >
                                  </div>
                                  <div class="keyword-journey-info-contnet">

                                    <span class="font-14">The Install stage of the Keyword Journey applies to keywords that have
                                      achieved installs, taps, and impressions within the lookback window but
                                      haven't yet resulted in a goal event. In this stage, bids are set to the target
                                      Cost Per Install (CPI) multiplied by the ratio of installs to taps.
                                      Additionally, the bids are subject to the maximum bid constraint and the
                                      spend cap constraint.
                                    </span>
                                    <ul>
                                      <li class="font-14">Target CPI: Defines the highest acceptable cost per install for a
                                        keyword that has resulted in installs but no goal events within the
                                        lookback window.
                                      </li>
                                      <li class="font-14">Max Bid: Max allowable bid when a keyword has resulted in installs
                                        but no goal events within the lookback window.
                                      </li>
                                      <li class="font-14">Min Bid: Bid value that will be set if the Spend Cap has been met for a
                                        keyword that has resulted in installs but no goal events. The intent is
                                        to slow or stop spend for an underperforming keyword.
                                      </li>
                                      <li class="font-14">Spend Cap: Max allowable spend on a keyword that has resulted in
                                        installs but no goal events within the lookback window. Once the
                                        spend cap is met, the keyword's bid is set to the Min Bid.
                                      </li>
                                    </ul>
                                    <span class="font-14">Note: You can always hover over each field to see info to it specifically.</span>             
                                  </div>
                                  </div>
                                </v-card>
                          </v-menu>
                        </template>
                      </h5>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p class="body--text-2 primarybase--text w-70">
                       Please capture below the constraints and behaviors you would like applied to keywords
                      that have resulted in installs, but no goal events, within the specified lookback window.
                      </p>
                      <v-form v-model="kwjInstallFormValid" ref="kwjInstallForm" >
                        <v-card elevation="0" class="d-flex align-center mt-4">
                          <v-row>
                            <v-col cols="12" lg="3" md="3" sm="12" >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                      v-bind="attrs"
                                      v-on="on"
                                      label="Target CPI"
                                      class=""
                                      prefix="$"
                                      v-model="install.targetCPI"
                                      type="number" 
                                      min="0"
                                      :rules="numberRules"
                                    ></v-text-field>
                                </template>
                              <span>
                                Defines the highest acceptable cost per install for a keyword
                                that has resulted in installs but no goal events within the
                                lookback window.
                              </span>
                              </v-tooltip> 
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="12" >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                  v-bind="attrs"
                                  v-on="on"
                                  label="Max Bid"
                                  class=""
                                  prefix="$"
                                  v-model="install.maxBid"
                                  type="number" 
                                  min="0"
                                  :rules="installMaxBidRules"
                              ></v-text-field>
                                </template>
                              <span>
                                Max allowable bid when a keyword has resulted in installs but
                                no goal events within the lookback window
                              </span>
                              </v-tooltip> 
                              
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="12" >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                  v-bind="attrs"
                                  v-on="on"
                                  label="Min Bid"
                                  class=""
                                  prefix="$"
                                  v-model="install.minBid"
                                  type="number" 
                                  min="0"
                                  :rules="installMinBidRules"
                              ></v-text-field>
                                </template>
                              <span>
                                Bid value that will be set if the Spend Cap has been met for a
                                keyword that has resulted in installs but no goal events. The
                                intent is to slow or stop spend for an underperforming keyword.
                              </span>
                              </v-tooltip> 
                              
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="12" v-if="general.asaOrgoal === 'GOAL Event'">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                  v-bind="attrs"
                                  v-on="on"
                                  label="Spend Cap"
                                  class=""
                                  prefix="$"
                                  v-model="install.spendCap"
                                  type="number" 
                                  min="0"
                                  :rules="numberRules"
                                ></v-text-field>
                                  
                                </template>
                              <span>
                                Max allowable spend on a keyword that has resulted in installs
                                but no goal events within the lookback window. Once the
                                spend cap is met, the keyword's bid is set to the Min Bid.
                              </span>
                              </v-tooltip> 
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-form>
                    <v-card elevation="0" class="w-100 d-flex justify-end mt-4" v-if="general.asaOrgoal === 'GOAL Event'" >
                      <v-btn color="primary" class="px-6" @click="goToEventStep">
                                  Next >
                      </v-btn>
                    </v-card>
                    <v-card elevation="0" class="w-100 d-flex justify-end mt-4" v-if="general.asaOrgoal === 'ASA Install'">
                      <v-btn color="primary" class="px-6" @click="goToSaveStep">
                                  Next >
                      </v-btn>
                    </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                    <!-- event  -->
                   <v-expansion-panel class="pa-2 mt-4" value="4" :disabled="!enableEventPanel" v-if="general.asaOrgoal === 'GOAL Event'">
                    <v-expansion-panel-header>
                      <span v-if="panel==4" class=" primarybase white--text rounded-count d-flex justify-center align-center mr-4">5</span> 
                      <span v-else class=" greylight white--text rounded-count d-flex justify-center align-center mr-4">5</span> 
                      <h5 class="primarybase--text h5--text">Event
                      
                        <template>
  
                        <v-menu
                          v-model="eventToolTip"
                          :close-on-content-click="false"
                          :nudge-width="200"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="greylight"
                              dark
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          <v-card>
                                <div class="keyword-journey-info-card">
                                  <div class=" d-flex justify-space-between align-center w-100">
                                  <h4 class="font-800">Event</h4>
                                  <v-icon class="" @click="eventToolTip=false"
                                    >mdi-close</v-icon
                                  >
                                </div>
                                <div class="keyword-journey-info-contnet">

                                  <span class="font-14">The Event stage of the Keyword Journey focuses on keywords with
                                    achieved goal events, showing promise for true value. The base bid is
                                    determined by the CPG target multiplied by the goal events-to-taps ratio
                                    for CPG targets, or the revenue per goal-to-target ROAS ratio multiplied
                                    by the goal events-to-taps ratio for ROAS targets. A bid boost, up to the
                                    Max Boost Multiplier, is applied to ensure adequate traffic, gradually
                                    reducing as goal events approach the Min Event Count. The assigned bid
                                    will always adhere to the maximum bid constraint.
                                  </span>
                                  <ul>
                                    <li class="font-14">Max Bid: Max allowable bid when a keyword has resulted in goal
                                      events within the lookback window totalling less than the Min Event
                                      Count.
                                    </li>
                                    <li class="font-14">Return On Ad Spend (ROAS): Defines the lowest acceptable return on
                                      ad spend (ROAS) for a keyword that has resulted in goal events within
                                      the lookback window totalling less than the Min Event Count.
                                    </li>
                                    <li class="font-14">Cost Per Goal (CPC): Defines the highest acceptable cost per goal
                                      event for a keyword that has resulted in goal events within the
                                      lookback window totalling less than the Min Event Count.
                                    </li>
                                    <li class="font-14">Max Boost Multiplier: Max percent increase allowable for a bid boost.
                                      Bid boosts are applied to a keyword that has resulted in goal events
                                      within the lookback window totalling less than the Min Event Count,
                                      and are intended to generate more results for promising keywords.
                                    </li>
                                    <li class="font-14">• Min Event Count: Amount of events required before a keywords bid
                                      amount can be based entirely on ROAS or CPG targets. Under this
                                      limit, automation works to balance leaning in on promising keywords
                                      with temporing calculated bid amounts based on limited data.
                                    </li>
                                  </ul>
                                  <span class="font-14">Note: You can always hover over each field to see info to it specifically.</span>             
                                </div>
                                </div>
                              </v-card>
                        </v-menu>

                        </template>

                      </h5>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p class="body--text-2 primarybase--text w-70">
                         Please capture below the constraints and behaviors you would like applied to keywords
                        that haven't resulted in any taps within the specified lookback window.
                      </p>
                      <v-form v-model="kwjEventFormValid" ref="kwjEventForm" >
                        <v-card elevation="0" class="d-flex align-end mt-4">
                          <v-row>
                            <v-col cols="12" lg="3" md="3" sm="12" class="d-flex align-end ">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Max Bid"
                                    prefix="$"
                                    class=""
                                    v-model="event.maxBid"
                                    type="number" 
                                    min="0"
                                    :rules="numberRules"
                                  ></v-text-field>
                                  
                                </template>
                              <span>
                                Max allowable bid when a keyword has resulted in goal events
                                within the lookback window totalling less than the Min Event
                                Count.
                              </span>
                              </v-tooltip> 
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="12" >
                              <div class="d-flex align-end ">
                                <v-radio-group v-model="event.roasOrCPG" class="mr-4">
                                    <v-radio
                                      label="ROAS"
                                      value="ROAS"
                                    ></v-radio>
                                    <v-radio
                                      label="CPG"
                                      value="CPG"
                                    ></v-radio>
                                  </v-radio-group>
                                  <v-tooltip v-if="event.roasOrCPG === 'ROAS'" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-bind="attrs"
                                        v-on="on"                                        
                                        label="Return On Ad Spend Ratio"
                                        class=""
                                        v-model="event.roasRatio"
                                        type="number" 
                                        min="0"
                                        :rules="numberRules"
                                      ></v-text-field>
                                    </template>
                                    <span>
                                      Defines the lowest acceptable return on ad spend (ROAS) for a
                                      keyword that has resulted in goal events within the lookback
                                      window totalling less than the Min Event Count.
                                    </span>
                                  </v-tooltip>
                                  <v-tooltip v-if="event.roasOrCPG === 'CPG'" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-bind="attrs"
                                        v-on="on"                                        
                                        label="Target CPG"
                                        class=""
                                        v-model="event.targetCPG"
                                        type="number" 
                                        min="0"
                                        :rules="numberRules"
                                        ></v-text-field>
                                    </template>
                                    <span>
                                      Defines the target cost per goal.
                                    </span>
                                  </v-tooltip>
                              </div>
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="12" class="d-flex align-end ">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <div v-bind="attrs" v-on="on" class="w-100">
                                  <v-select
                                    placeholder="Select"
                                    label="Max Boost Multiplier"
                                    class=""
                                    :items="maxBoostMultiplierList"
                                    v-model="event.maxBoostMultiplier"
                                  ></v-select>

                                  </div>
                                </template>
                              <span>
                                Max percent increase allowable for a bid boost. Bid boosts are
                                applied to a keyword that has resulted in goal events within the
                                lookback window totalling less than the Min Event Count, and
                                are intended to generate more results for promising keywords.
                              </span>
                              </v-tooltip> 
                            </v-col>
                            <v-col cols="12" lg="3" md="3" sm="12" class="d-flex align-end ">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    placeholder=""
                                    label="Min Event Count"
                                    class=""
                                    v-model="event.minEventCount"
                                    type="number" 
                                    min="0"
                                    :rules="numberRules"
                                  ></v-text-field>
                                </template>
                              <span>
                                Amount Of events required before a keywords bid amount can
                                be based entirely on ROAS or CPG targets. Under this limit,
                                automation works to balance leaning in on promising keywords
                                with temporing calculated bid amounts based on limited data.
                              </span>
                              </v-tooltip> 
                            </v-col>
                          </v-row>

                        </v-card>
                      </v-form>
                    <v-card elevation="0" class="w-100 d-flex justify-end mt-4">
                      <v-btn color="primary" class="px-6" @click="goToSaveStep">
                                  Next >
                      </v-btn>
                    </v-card>
                    
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                </v-expansion-panels>
              </template>
              </div>

          </template>
          <div class="w-100 d-flex justify-end mt-4">
            <v-btn color="primary" class="px-6" @click="createRules" :disabled="!enableSave">Save ></v-btn>
    
          </div>
        </v-container>
      </div>
    </v-app>
  </div>
</template>

<script>

import Vue from 'vue';
import {
  OWN_APPS,
  APP_METADATA,
} from '../utils/apptweakqueries';
import { apolloClient } from "../apollo/apollo-client";
import { LSS } from '@core/services';
import { getOwnApps } from '../utils/util';
import { RuleCheckFrequencies } from '@shared/enums/arenums';
import {
  GET_SLACKS,
  GET_EMAILS,
  CREATE_KWJ_RULES,
} from '../utils/arrules-queries';
import {
  GET_CAMPAIGNS_BY_APP,
  GET_GOALS_FROM_APP
} from 'src/utils/apollo-queries';
import DuplicateEmailComboboxService from "@services/component-services/dulicate-email-combobox.service";

export default Vue.extend({
  name: 'Overview',
  data() {
    return {
      enableImpPanel: false,
      enableTapPanel: false,
      enableInstallPanel: false,
      enableEventPanel: false,
      enableSave: false,
      impressionToolTip: false,
      tapToolTip: false,
      insatllToolTip: false,
      eventToolTip: false,
      goalList: [],
      loadingGoals: false,
      fullscreenLoader: null,
      roasRatioList: [0.25, 0.50, 0.75, 1],
      maxBoostMultiplierList: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
      kwjGeneralFormValid: true,
      kwjImpressionFormValid: true,
      kwjTapFormValid: true,
      kwjInstallFormValid: true,
      kwjEventFormValid: true,
      panel: 0,
      radioGroup: null,
      checkbox: null,
      loadingApps: false,
      loadingCamps: false,
      appsList: [],
      campsList: [],
      selectedMailIds: [],
      externalMailIds: [],
      selectAllEmails: false,
      selectAllSlacks: false,
      selectedSlacks: [],
      emailList: [],
      selectAllEmailIcon: 'mdi-crop-square',
      selectAllSlackIcon: 'mdi-crop-square',
      slackChannelList: [],
      csvEmailRules: [
        (v) => {
          if(v.length === 0) return true
          if((v.length > 0) && (/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(,[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/.test(
            v
          ))) return true
          else return this.$t('createAR.enterEmail')
        },
        (v) => {
          if(!this.general.sendReports) return true
          if(this.selectedMailIds && this.selectedMailIds.length > 0) return true
          if(this.selectedSlacks && this.selectedSlacks.length > 0) return true
          if(this.externalMailIds && this.externalMailIds.length > 0) return true
          return 'When send report is checked, you need to to select an existing email Id or a slack channel or provide an external email Id'
        },
      ],
      sendReportRule: [
        (v) => {
          if(!this.general.sendReports) return true
          if(this.selectedMailIds && this.selectedMailIds.length > 0) return true
          if(this.selectedSlacks && this.selectedSlacks.length > 0) return true
          if(this.externalMailIds && this.externalMailIds.length > 0) return true
          return 'When send report is checked, you need to to select an existing email Id or a slack channel or provide an external email Id'
        },
      ],
      vSelectEmailRules: [
      ],
      vSelectRules: [
      ],
      notEmptyRule: [
        (v) => (!!v) || 'Cannot be empty',
      ],
      lookbackRule: [
        (v) => (!!v) || 'Enter lookback',
        (v) => (+v > 0 && +v < 31) || 'Lookback should be in between 1 and 30 days',
      ],
      numberRules: [
        (v) => (+v > 0) || 'Value should be greater than 0',
      ],
      impressionMaxBidRules: [
        // (v) => {
        //   if(v > this.impression.minBid && v > 0) {
        //     return true
        //   }
        //   return 'Max Bid must be greater than 0 and more than Min Bid'
        // },
        (v) => (+v > 0) || 'Value should be greater than 0',
      ],
      impressionMinBidRules: [
        (v) => {
          if(+v < +this.impression.maxBid && +v > 0) {
            return true
          }
          return 'Min Bid must be greater than 0 and less than Max Bid'
        },
      ],
      impressionBidIncreaseRules: [
        (v) => {
          if(+v < +this.impression.maxBid && +v > 0) {
            return true
          }
          return 'Bid increase must be greater than 0 and less than Max Bid'
        },
      ],
      tapMaxBidRules: [
        (v) => (+v > 0) || 'Value should be greater than 0',
      ],
      tapMinBidRules: [
        (v) => {
          if(+v < +this.tap.maxBid && +v > 0) {
            return true
          }
          return 'Min Bid must be greater than 0 and less than Max Bid'
        },
      ],
      tapBidIncreaseRules: [
        (v) => {
          if(+v < +this.tap.maxBid && +v > 0) {
            return true
          }
          return 'Bid increase must be greater than 0 and less than Max Bid'
        },
      ],
      installMaxBidRules: [
        (v) => (+v > 0) || 'Value should be greater than 0',
      ],
      installMinBidRules: [
        (v) => {
          if(+v < +this.install.maxBid && +v > 0) {
            return true
          }
          return 'Min Bid must be greater than 0 and less than Max Bid'
        },
      ],
      general: {
        checkFrequency: RuleCheckFrequencies.HOURLY,
        isDated: false,
        dateRange: null,
        isIndefinite: true,
        selectedApp: null,        
        selectedCamps: [],
        selectedGoals: [],
        sendReports: false, 
        emailIds: [],
        slackIds: [],
        lookback: 30,
        name: null,
        asaOrgoal: 'ASA Install',
      },
      impression: {
        maxBid: null,
        minBid: null,
        bidIncrease: null
      },
      tap: {
        spendCap: null,
        maxBid: null,
        minBid: null,
        bidIncrease: null
      },
      install: {
        spendCap: null,
        maxBid: null,
        minBid: null,
        targetCPI: null
      },
      event: {
        minEventCount: null,
        maxBid: null,
        roasRatio: null,
        maxBoostMultiplier: 0.5,
        roasOrCPG: 'ROAS',
        targetCPG: null
      },
      comboboxService: new DuplicateEmailComboboxService({
        component : this,
        externalEmailPropertyName : "externalMailIds",
        mainEmailPropertyName : "selectedMailIds"
      }),
    }
  },
  apollo: {
    getEmails: {
      query: GET_EMAILS,
      update(data) {
        this.emailList = data.getEmails ? data.getEmails : [];
      },
    },
    getSlacks: {
      query: GET_SLACKS,
      update(data) {
        this.slackChannelList = data.getSlacks
      },
    },
  },
  watch: {
    externalMailIds: {
      handler(newVal, oldVal){   
        this.validateGeneralForm()
      }   
    },
    selectedMailIds: {
      handler(newVal, oldVal){   
        this.validateGeneralForm()
      }   
    },
    selectedSlacks: {
      handler(newVal, oldVal){   
        this.validateGeneralForm()
      }   
    },
  },
  methods: {
    onModelValueChange(){
      this.comboboxService.checkForDuplicateRemovalAndNotifyError();
    },
    goToRules() {
      return this.$router.push({ path:'/arrules'});
    },
    async validateGeneralForm() {
      await this.$refs.kwjGeneralForm.validate();
      if (!this.kwjGeneralFormValid) {
        return;
      }
    },
    async goToImpressionStep() {
      await this.$refs.kwjGeneralForm.validate();
      if (!this.kwjGeneralFormValid) {
        this.panel = 0
        this.enableImpPanel = false
        this.enableTapPanel = false
        this.enableInstallPanel = false
        this.enableEventPanel = false
        return;
      }
      this.enableImpPanel = true
      this.panel = 1
    },
    async goToTapStep() {
      await this.$refs.kwjImpressionForm.validate();
      if (!this.kwjImpressionFormValid) {
        this.panel = 1
        this.enableTapPanel = false
        this.enableInstallPanel = false
        this.enableEventPanel = false
        return;
      }
      this.enableTapPanel = true
      this.panel = 2
    },
    async goToInstallStep() {
      await this.$refs.kwjTapForm.validate();
      if (!this.kwjTapFormValid) {
        this.panel = 2
        this.enableInstallPanel = false
        this.enableEventPanel = false
        return;
      }
      this.enableInstallPanel = true
      this.panel = 3
      if (this.general.asaOrgoal === 'ASA Install') {
        this.install.spendCap = 1000000
      }
    },
    async goToEventStep() {
      await this.$refs.kwjInstallForm.validate();
      if (!this.kwjInstallFormValid) {
        this.panel = 3
        this.enableEventPanel = false
        return;
      }
      this.enableEventPanel = true
      this.panel = 4
    },
    async goToSaveStep() {
      if (this.general.asaOrgoal === 'ASA Install') {
        this.enableSave = true
        this.panel = 5
        return;
      }
      await this.$refs.kwjEventForm.validate();
      if (!this.kwjEventFormValid) {
        this.panel = 4
        this.enableSave = false
        return;
      }
      this.enableSave = true
      this.panel = 5
    },
    async getOwnApps() {
      await apolloClient
        .query({
          query: OWN_APPS,
          variables: {
            from: 'KWJ',
            at: Date.now()
          },
        })
        .then((res) => {
          const apps = res.data.getAllOwnApps;
        })
        .catch((error) => {
        });
    },
    populateApps(){
      this.appsList = LSS.ownApps
      this.loadingApps = false;
      // this.appsList.push({
      //     adamId: 123,
      //     appName: '132 Apps',
      //     appIcon: 'mdi-cellphone',
      //     attrExist: false,
      // })
      if(this.appsList.length > 0) {
        this.general.selectedApp = this.appsList[0]  
        this.changeApp(this.appsList[0])
      }
    },
    async init() {
      this.loadingApps = true; 
      if(!LSS.ownApps) LSS.ownApps = []
      if(LSS.ownApps && LSS.ownApps.length > 0) {
        this.populateApps()
      }else{
        LSS.ownApps = []
        // await getOwnApps('KWJ', this)
        if(LSS.asaInt) {
          await getOwnApps('KWJ', this)
        }else{
          this.populateApps()
        }
      }
    },
    async changeApp(e) {
      this.campsList = []
      this.goalList = []
      this.general.selectedCamps = []
      this.general.selectedGoals = []
      this.loadingCamps = true;
      await apolloClient
        .query({
          query: GET_CAMPAIGNS_BY_APP,
          variables: {
            appId: this.general.selectedApp.adamId,
          },
        })
        .then((res) => {
          this.loadingCamps = false;
          const campaigns = res.data.getCampaignsByApp;
          this.campsList = campaigns
        })
        .catch((error) => {
          this.loadingCamps = false;
        });

        if (this.general.selectedApp.attrExist) {
          this.loadingGoals = true;
          this.$apollo
            .query({
              query: GET_GOALS_FROM_APP,
              variables: {
                appId: this.general.selectedApp.adamId,
              },
            })
            .then((res) => {
              this.loadingGoals = false;
              this.goalList = res.data.getGoalsFromApp
            })
            .catch((error) => {
              this.loadingGoals = false;
            });
        }
    },
    campaignsSelected(e) {
      // console.log(e)
    },
    async createRules() {
      if (this.general.asaOrgoal === 'GOAL Event') {
        await this.$refs.kwjEventForm.validate();
        if (!this.kwjEventFormValid) {
          this.panel = 4
          return;
        }
      }

      await this.$refs.kwjGeneralForm.validate();
      if (!this.kwjGeneralFormValid) {
        this.panel = 0
        return;
      }
      await this.$refs.kwjImpressionForm.validate();
      if (!this.kwjImpressionFormValid) {
        this.panel = 1
        return;
      }
      await this.$refs.kwjTapForm.validate();
      if (!this.kwjTapFormValid) {
        this.panel = 2
        return;
      }      
      await this.$refs.kwjInstallForm.validate();
      if (!this.kwjInstallFormValid) {
        this.panel = 3
        return;
      }
      this.general.emailIds = this.selectedMailIds.concat(this.externalMailIds || []) || []
      this.general.slackIds = this.selectedSlacks || []
      this.general.lookback = +this.general.lookback

      const req = {
        general: this.general,
        impression: this.impression,
        tap: this.tap,
        install: this.install,
        event: this.event,
      }
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: CREATE_KWJ_RULES,
          variables: {
            data: JSON.stringify(req),
            at: Date.now()
          },
        })
        .then((res) => {
          this.fullscreenLoader.hide();
          const data = res.data.createKeywordJourneyRules
          if(data.successMessage === "OK"){
            this.$notify.success('Rules created successfully');
            return this.$router.push({ path:'/arrules'});
          }else{
            this.$notify.error('Unable to create rules');
          }
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error('Unable to create rules');
        });
    },
    toggleEmailSelectAll(i) {
      if (!this.selectAllEmails) {
        this.selectAllEmails = true
        this.selectedMailIds = this.emailList.map((e) => e.Email)
        this.selectAllEmailIcon = 'mdi-checkbox-multiple-marked'
      } else {
        this.selectAllEmails = false
        this.selectedMailIds = []
        this.selectAllEmailIcon = 'mdi-crop-square'
      }
    },
    toggleSlackSelectAll() {
      if (!this.selectAllSlacks) {
        this.selectAllSlacks = true
        this.selectedSlacks = this.slackChannelList.map(
          (e) => e.IntegrationID
        );
        this.selectAllSlackIcon = 'mdi-checkbox-multiple-marked'
      } else {
        this.selectAllSlacks = false
        this.selectedSlacks = []
        this.selectAllSlackIcon = 'mdi-crop-square'
      }
    },
    emailSelectionChanged() {
      this.comboboxService.checkAndInitiateADuplicateRemovalInOtherEmail();
      if(this.emailList && this.emailList.length > 0 && this.selectedMailIds.length === this.emailList.length) {
        this.selectAllEmails = true
        this.selectAllEmailIcon = 'mdi-checkbox-multiple-marked'
      }else{
        this.selectAllEmails = false
        this.selectAllEmailIcon = 'mdi-crop-square'
      }
    },
    slackSelectionChanged() {
      if(this.selectedSlacks && this.slackChannelList.length > 0 && this.selectedSlacks.length === this.slackChannelList.length) {
        this.selectAllSlacks = true
        this.selectAllSlackIcon = 'mdi-checkbox-multiple-marked'
      }else{
        this.selectAllSlacks = false
        this.selectAllSlackIcon = 'mdi-crop-square'
      }
    },
  },
  mounted() {
    // this.getOwnApps()
    this.init();
    this.$root.$on('apps-refreshed', (data) => {
      this.loadingApps = false;
      this.populateApps()
    });
  }
})
</script>